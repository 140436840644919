<mat-toolbar
  color="primary"
  role="toolbar"
  class="bg-component-bg-light text-black dark:bg-component-bg-dark dark:text-white"
>
  <app-notifications></app-notifications>
  <mat-toolbar-row
    class="gap-7 border-b border-solid border-b-neutral-300 dark:border-b-neutral-700"
  >
    <!-- Creekmore Marketing Icon -->
    <div
      class="flex flex-shrink-0 flex-grow-0 basis-6 flex-col items-center justify-center"
    >
      <img src="/assets/icons/icon-light-mode.svg" class="w-8 dark:hidden" />
      <img
        src="/assets/icons/icon-dark-mode.svg"
        class="hidden w-8 dark:block"
      />

      <div class="hidden text-xs drop-shadow-sm md:block">Console</div>
    </div>

    <app-menu class="flex-1"></app-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container
  fullscreen
  autosize
  class="top-16 bg-body-bg-light dark:bg-body-bg-dark"
  [ngClass]="
    notificationService.countVisibleNotifications() ? 'notification-resize' : ''
  "
>
  <mat-sidenav
    [opened]="sidenavService.isOpen"
    mode="side"
    class="dark:bg-component-bg-dark"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content
    class="main-console-content relative box-content h-[calc(100%-100px)] overflow-hidden px-0 pb-[100px] pt-0 transition-all [&.hide-logs_app-messages]:hidden"
    [ngClass]="{ 'hide-logs': !this.settingsService.globalShowLogs }"
  >
    <router-outlet></router-outlet>
    <app-messages class="absolute bottom-5 left-5 right-5 z-10"></app-messages>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-search-popup></app-search-popup>

<app-user-dialog></app-user-dialog>

<app-fab></app-fab>
