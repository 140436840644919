import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DialogFormComponent } from '@shared/components/dialog-form/dialog-form.component';
import { Subject, Subscriber, race, of } from 'rxjs';
import { KeybindService } from '@services/keybind.service';
import { ClientSearchService } from '@services/client-search.service';
import { Business, BusinessLocation } from '@helpers/types/client';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { OverlayService } from '@services/overlay.service';
import { AprilFoolsComponent } from '../overlay-loader/april-fools/april-fools.component';
import { SpinnerComponent } from '../overlay-loader/spinner/spinner.component';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserSettingsService } from '@services/user-settings.service';
import { LocalStorageService } from 'ngx-webstorage';

export interface SearchPopupConfig {
  mode: 'link' | 'select';
  type?: 'business' | 'location';
  multiple?: boolean;
  headerText?: string;
  exclude_ids?: number[];
  observer?: Subscriber<number[]>;
}

@Component({
  standalone: true,
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    FontAwesomeModule,
    NgxSkeletonLoaderModule,
  ],
})
export class SearchPopupComponent implements OnInit {
  private subjectSearch$ = new Subject<string>();

  private dialogRef: MatDialogRef<any, any> | null = null;

  businesses: Business[] = [];
  searchText: string = '';
  selections: number[] = [];
  mode: SearchPopupConfig['mode'] = 'link';
  type: Exclude<SearchPopupConfig['type'], undefined> = 'business';
  multiSelect: boolean = false;
  removeSearchBox: boolean = false;

  results: Business[] | BusinessLocation[] = [];

  themeColor: 'dark' | 'light' = 'dark';

  isLoading = false;

  exclude_ids: number[] = [];

  @ViewChild('searchDialog')
  searchDialog: TemplateRef<any> | undefined;

  constructor(
    private keybindService: KeybindService,
    private dialog: MatDialog,
    private searchService: ClientSearchService,
    private overlayService: OverlayService,
    private localStorageService: LocalStorageService,
    private settingsService: UserSettingsService,
  ) {
    this.localStorageService.observe('global-theme').subscribe((theme) => {
      this.themeColor = this.settingsService.getCurrentTheme();
    });
  }

  ngOnInit(): void {
    this.subjectSearch$
      .pipe(
        tap((searchValue) => (this.isLoading = !!searchValue)),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => {
          if (prev === curr) {
            this.isLoading = false;
            return true;
          } else {
            this.isLoading = true;
            return false;
          }
        }),
      )
      .subscribe((searchValue) => {
        //get matching businesses
        if (searchValue != '') {
          this.searchService
            .searchBusinesses(searchValue, [])
            .subscribe((response) => {
              this.isLoading = false;
              this.businesses =
                this.exclude_ids.length > 0
                  ? response.filter((b) => !this.exclude_ids.includes(b.id))
                  : response;
            });
        } else {
          this.isLoading = false;
          this.businesses = [];
        }
      });

    race(
      this.keybindService.match('J', ['ctrlKey']),
      this.keybindService.match('J', ['metaKey']),
    ).subscribe(() => {
      this.openSearchDialog();
    });

    this.konami();

    this.searchService.listenForOpenDialog().subscribe((config) => {
      this.openSearchDialog(config);
    });
  }

  //TODO: Allow keeping selected elements after searching
  elementSelectionChange($event: MatSelectionListChange) {
    // group with previous selected IDs, then make unique and remove any unselected
    const removing: number[] = [];
    this.selections = this.selections
      .concat(
        $event.options.reduce((adding, o) => {
          if (o.selected) {
            adding.push(o.value);
          } else {
            removing.push(o.value);
          }
          return adding;
        }, [] as number[]),
      )
      .filter((v, i, a) => a.indexOf(v) === i && !removing.includes(v));

    // don't close immediately if multiple elements can be selected
    if (this.multiSelect === true || $event.options.length === 0) {
      return;
    }

    this.closeSearchDialog(true);
  }

  openSearchDialog(config?: SearchPopupConfig): void {
    if (this.dialogRef !== null) return;

    this.mode = config?.mode ?? 'link';
    this.multiSelect = config?.multiple ?? false;
    this.exclude_ids = config?.exclude_ids ?? [];
    this.type = config?.type ?? 'business';

    // clear search every time the dialog is opened
    this.clearSearch();

    this.dialogRef = this.dialog.open(DialogFormComponent, {
      width: '400px',
      data: {
        headerText: config?.headerText || 'Find Client',
        buttonText:
          config?.mode === 'select' && config?.multiple === true
            ? 'Select'
            : null,
        cancelText: 'Close',
        template: this.searchDialog,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.dialogRef = null;

      if (!config?.observer) {
        return;
      }

      if (result && this.mode === 'select') {
        config.observer.next(this.selections);
      } else {
        config.observer.next();
      }
      config.observer.complete();
    });
  }

  closeSearchDialog(success: boolean = false): void {
    this.dialogRef?.close(success);
  }

  clearSearch(withSelections = true): void {
    this.searchText = '';
    this.businesses = [];
    if (withSelections) {
      this.selections = [];
    }
    this.subjectSearch$.next('');
  }

  searchEntered(search: string) {
    this.subjectSearch$.next(search?.trim());
  }

  private konami() {
    const kCode = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'KeyB',
      'KeyA',
    ];
    let currentCode: string[] = [];
    this.keybindService.listenAllKeys().subscribe((key) => {
      if (key.code) {
        currentCode.push(key.code);

        if (currentCode.length > kCode.length) {
          currentCode.shift();
        }

        if (
          kCode.every(
            (code: string, index: number) => code === currentCode[index],
          )
        ) {
          const deletingClose = this.overlayService.open({
            component: SpinnerComponent,
            config: {
              data: {
                spinnerText:
                  'Deleting all Creekmore Marketing data - Exclusivity Map,<br>database, CMM Console, and client Basecamps',
              },
            },
            afterClose: () => {
              const aprilFoolsClose = this.overlayService.open({
                component: AprilFoolsComponent,
              });
            },
          });
          setTimeout(() => {
            this.overlayService.close(deletingClose);
          }, 10000);
        }
      }
    });
  }
}
