<ng-template #userDetailsDialog>
  <div
    class="flex flex-col items-center justify-start gap-4 text-black dark:text-white"
  >
    <img
      *ngIf="user.avatar"
      [src]="user.avatar"
      class="max-w-[130px] rounded-full"
    />
    <div>
      <h2 class="text-balance text-center text-xl font-medium">
        {{ user.name }}
      </h2>
      <h4 *ngIf="user.title" class="mt-2 text-balance text-center text-base">
        {{ user.title }}
      </h4>
    </div>
    <p *ngIf="user.email" class="m-0 text-balance text-center">
      {{ user.email }}
    </p>
    <p *ngIf="user.bio" class="m-0 text-balance text-center">{{ user.bio }}</p>

    <p
      class="m-0 mt-10 text-balance text-center font-mono text-sm opacity-60"
      *ngIf="user.cmm_id"
    >
      <span matTooltip="CMM Unique ID" matTooltipPosition="above">
        {{ user.cmm_id }}
      </span>
      |
      <span matTooltip="Auto-generated Unique Name" matTooltipPosition="above">
        {{ user.cmm_unique_name }}
      </span>
    </p>

    <!-- TODO: Enable creating pings/circles dynamically -->
    <!--
        <button mat-raised-button color="accent" class="ping-button" *ngIf="user.basecamp_id" (click)="openBasecampPing()">Ping Them!</button>
    -->
  </div>
</ng-template>
