import { Component } from '@angular/core';

@Component({
  selector: 'app-april-fools',
  templateUrl: './april-fools.component.html',
  styleUrls: ['./april-fools.component.scss'],
  standalone: true,
})
export class AprilFoolsComponent {
  constructor() {}
}
