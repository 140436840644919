import { Component } from '@angular/core';
import { MessageService } from '@services/message.service';
import { MatButton } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from '@angular/material/expansion';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FaIconComponent,
    MatExpansionPanelDescription,
    MatToolbar,
    MatButton,
  ],
})
export class MessagesComponent {
  constructor(public messageService: MessageService) {}
}
