<ng-template #searchDialog>
  @if (!removeSearchBox) {
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input
        matInput
        [(ngModel)]="searchText"
        (ngModelChange)="searchEntered($event)"
        autocomplete="off"
      />
      <button
        mat-icon-button
        matSuffix
        class="text-warn"
        (click)="clearSearch(false)"
      >
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
      </button>
    </mat-form-field>
  }

  @if (this.selections.length > 0) {
    <div>
      {{ this.selections.length }}
      {{ this.selections.length === 1 ? "item" : "items" }} selected
    </div>
  }

  @if (searchText && (businesses.length > 0 || isLoading)) {
    <div class="max-h-[600px] overflow-y-auto">
      @if (isLoading) {
        <ngx-skeleton-loader
          count="3"
          [theme]="{
            height: '50px',
            'background-color': themeColor === 'dark' ? '#323232' : '',
            opacity: '0.4'
          }"
          [animation]="themeColor === 'dark' ? 'progress-dark' : 'progress'"
        ></ngx-skeleton-loader>
      } @else {
        @if (mode === "link") {
          @if (type === "business") {
            <mat-nav-list>
              @for (biz of businesses; track biz.id) {
                <a
                  mat-list-item
                  routerLink="/clients/{{ biz.id }}"
                  (click)="closeSearchDialog()"
                  class="h-auto min-h-[48px] overflow-hidden rounded py-2 hover:before:transition-opacity"
                >
                  <ng-container
                    mat-line
                    *ngTemplateOutlet="businessItem; context: { biz: biz }"
                  ></ng-container>
                </a>
              }
            </mat-nav-list>
          }
        } @else if (mode === "select") {
          <mat-selection-list
            [multiple]="multiSelect"
            (selectionChange)="elementSelectionChange($event)"
          >
            @if (type === "business") {
              @for (biz of businesses; track biz.id) {
                <mat-list-option
                  [value]="biz.id"
                  class="h-auto min-h-[48px] overflow-hidden rounded py-2 hover:before:transition-opacity"
                  [selected]="multiSelect && selections.includes(biz.id)"
                >
                  <ng-container
                    *ngTemplateOutlet="businessItem; context: { biz: biz }"
                  ></ng-container>
                </mat-list-option>
              }
            } @else if (type === "location") {
              @for (biz of businesses; track biz.id) {
                @for (loc of biz.locations; track loc.id) {
                  <mat-list-option
                    [value]="loc.id"
                    class="h-auto min-h-[48px] overflow-hidden rounded py-2 hover:before:transition-opacity"
                    [selected]="multiSelect && selections.includes(biz.id)"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        locationItem;
                        context: { biz: biz, loc: loc }
                      "
                    ></ng-container>
                  </mat-list-option>
                }
              }
            }
          </mat-selection-list>
        }
      }
    </div>
  } @else if (searchText) {
    <div>No results were found.</div>
  }
</ng-template>

<ng-template #businessItem let-biz="biz">
  <div mat-line>{{ biz.name }}</div>

  @for (domain of biz.domains; track domain.domain) {
    <div mat-line class="overflow-hidden text-ellipsis text-[#888]">
      {{ domain.domain }}

      @if (domain.cms_platform_code) {
        ({{ domain.cms_platform_code }})
      }
    </div>
  }
</ng-template>

<ng-template #locationItem let-biz="biz" let-loc="loc">
  <div mat-line>{{ biz.name }}</div>
  <div mat-line class="overflow-hidden text-ellipsis text-[#888]">
    {{ loc.address }}
  </div>
</ng-template>
