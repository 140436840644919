import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { KeyNames, hasKeycode, KEYS } from './keybind.keycodes';
import { ModifierKey, hasModifierKey } from './keybind.modifiers';

export class MatchConfig {
  public listenOn: EventTarget = window;

  constructor(init: Partial<MatchConfig>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class KeybindService {
  constructor() {}

  public match(
    // The name of the key to match
    matchKey: KeyNames,
    // A list of modifier keys that must
    // be present for the event to fire
    matchModifiers: ModifierKey[] = [],
    // Extra options
    options?: MatchConfig,
  ): Observable<KeyboardEvent> {
    // Get your config. If no options or any of
    // its properties are null, the default
    // values are used
    const { listenOn } = new MatchConfig(options ?? {});

    return new Observable((observer) => {
      const listener$ = fromEvent<KeyboardEvent>(listenOn, 'keydown');

      listener$.subscribe((event: KeyboardEvent) => {
        if (
          hasKeycode(event, KEYS[matchKey]) &&
          (!matchModifiers.length || hasModifierKey(event, ...matchModifiers))
        ) {
          event.preventDefault();
          observer.next(event);
        }
      });
    });
  }

  public listenAllKeys(): Observable<KeyboardEvent> {
    return new Observable((observer) => {
      const listener$ = fromEvent<KeyboardEvent>(window, 'keydown');
      listener$.subscribe((event: KeyboardEvent) => {
        observer.next(event);
      });
    });
  }
}
