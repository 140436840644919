<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <fa-icon [icon]="['fas', 'code']"></fa-icon>
    </mat-panel-title>
    <mat-panel-description> Session Logs </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-template #noMessages>
    <mat-toolbar class="placeholder">No messages logged yet.</mat-toolbar>
  </ng-template>
  <div *ngIf="messageService.messages.length; else noMessages">
    <button
      mat-raised-button
      color="accent"
      class="mr-5"
      (click)="messageService.saveFile()"
    >
      SAVE
    </button>
    <button
      mat-raised-button
      color="accent"
      class="copy"
      (click)="messageService.copyToClipboard()"
    >
      COPY
    </button>
    <button
      mat-raised-button
      color="accent"
      class="float-right"
      (click)="messageService.clear()"
    >
      CLEAR
    </button>
    <ol class="mt-5 max-h-120 list-none overflow-y-auto font-mono text-xs">
      <li
        *ngFor="let message of messageService.messages"
        class="mb-1 flex flex-row justify-start gap-3"
      >
        <span class="flex-none basis-auto text-red-700 dark:text-red-600">
          {{ message.time }}
        </span>
        <span
          class="inline-block flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap align-top"
        >
          {{ message.text }}
        </span>
      </li>
    </ol>
  </div>
</mat-expansion-panel>
