<div class="flex w-full flex-row justify-between">
  <!-- Left Side Content -->
  <div
    class="left-content flex flex-shrink-0 flex-grow-0 basis-auto flex-row items-center justify-start gap-5 [&_.active]:border-0 [&_.active]:border-b-4 [&_.active]:border-solid [&_.active]:border-b-primary [&_.active]:text-primary"
  >
    <a
      mat-button
      routerLink="dashboard"
      routerLinkActive="active"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
    >
      Dashboard
    </a>
    <a
      mat-button
      routerLink="clients"
      routerLinkActive="active"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
    >
      Clients
    </a>
    <a
      mat-button
      routerLink="teams"
      routerLinkActive="active"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
    >
      Teams
    </a>
    <button
      mat-button
      [matMenuTriggerFor]="menuTools"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
    >
      Tools
    </button>

    <!-- TODO: Main menu button for mobile -->
    <!--
    <button mat-icon-button [matMenuTriggerFor]="menuMain" class="md:hidden">
      <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </button>
    -->

    <div
      class="mx-2 my-0 h-8 w-0 rounded-md border-l-[3px] border-solid border-l-[#0003] dark:border-l-[#fff3]"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
    ></div>

    <button
      mat-button
      class="text-[1.2rem]"
      (click)="this.clientSearch.openSearchDialog()"
      *ngIf="auth.hasPermissionTo$('access console businesses') | async"
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
    >
      <fa-icon [icon]="['fas', 'search']" class="pr-2"></fa-icon>
      Find Client
    </button>
  </div>

  <!-- Right Side Content -->
  <div
    class="flex flex-shrink-0 flex-grow-0 basis-auto flex-row items-center justify-end gap-5"
  >
    <div
      class="flex flex-row items-center gap-5"
      *ngIf="auth.isAuthenticated$ | async"
    >
      <a
        mat-icon-button
        class="flex flex-row items-center justify-center"
        *ngIf="showAprilFools"
        matTooltip="???"
        matTooltipPosition="below"
        (click)="showAprilFoolsDialog()"
      >
        <img
          src="/assets/icons/rabbit.png"
          class="inline-block h-auto w-8 max-w-none"
        />
      </a>

      <a
        mat-icon-button
        class="flex items-center justify-center"
        href="https://3.basecamp.com/3194614"
        target="_blank"
        rel="noreferrer"
        *ngIf="basecampLoggedIn"
        matTooltip="CMM Basecamp"
        matTooltipPosition="below"
      >
        <img
          src="/assets/icons/basecamp-icon.png"
          class="inline-block h-auto w-8 max-w-none"
        />
      </a>

      <a
        mat-icon-button
        class="flex flex-row items-center justify-center"
        matTooltip="CreekCamp Extension"
        matTooltipPosition="below"
        href="https://chrome.google.com/webstore/detail/creekcamp/eoimfchndoljmbgddigibamkeeicpgem"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/assets/icons/creekcamp-icon.png"
          class="inline-block h-auto w-8 max-w-none"
        />
      </a>
    </div>

    <button
      mat-button
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
      (click)="auth.login()"
      *ngIf="(auth.isAuthenticated$ | async) === false"
    >
      Login
    </button>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menuTheme"
      class="text-xl"
      matTooltip="Change Theme"
      matTooltipPosition="below"
    >
      <fa-icon
        [icon]="['fas', 'moon']"
        *ngIf="settingsService.globalTheme === 'dark'"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'sun']"
        *ngIf="settingsService.globalTheme === 'light'"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'eclipse']"
        *ngIf="settingsService.globalTheme === 'auto'"
      ></fa-icon>
    </button>

    <button
      mat-button
      class="box-border h-16 rounded-none text-base text-black dark:text-white"
      [matMenuTriggerFor]="menuAccount"
      matTooltip="User Details"
      matTooltipPosition="below"
      *ngIf="auth.user$ | async; let user"
    >
      @if (user.avatar) {
        <img
          [src]="user.avatar"
          alt="user avatar"
          class="mr-3 h-8 w-8 rounded-full"
        />
      }
      {{ user.name.split(" ")[0] }}
    </button>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menuHelp"
      matTooltip="Help"
      matTooltipPosition="below"
      class="text-xl [&_.mat-badge-content]:top-1"
    >
      <fa-icon [icon]="['fas', 'circle-question']"></fa-icon>

      @if (appUpdating || appUpdateAvailable) {
        <div
          class="absolute right-0 top-0 z-10 h-5 w-5 rounded-3xl bg-accent p-1 text-white"
          [matTooltip]="appUpdateAvailable ? 'Update Available' : 'Updating'"
          matTooltipPosition="below"
        >
          <fa-icon
            [icon]="['fas', appUpdateAvailable ? 'arrow-up' : 'refresh']"
            [animation]="
              appUpdating && !appUpdateAvailable ? 'spin' : undefined
            "
            class="[&_svg]:block [&_svg]:h-full [&_svg]:w-full"
          ></fa-icon>
        </div>
      }
    </button>
  </div>
</div>

<mat-menu #menuTools="matMenu">
  <a
    mat-menu-item
    routerLink="clients/exclusivity"
    *ngIf="auth.hasPermissionTo$('access console businesses') | async"
    >Exclusivity Map</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    [matMenuTriggerFor]="menuScripts"
    >Scripts</a
  >
  <a
    mat-menu-item
    routerLink="tools/hd-errors"
    *ngIf="auth.hasPermissionTo$('access console admin_areas') | async"
    >HD 404 Errors</a
  >
  <a
    mat-menu-item
    routerLink="tools/calculators"
    *ngIf="auth.hasPermissionTo$('access console businesses') | async"
    >Calculators</a
  >
  <a
    mat-menu-item
    routerLink="tools/image-optimizer"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Image Optimizer</a
  >
  <a
    mat-menu-item
    routerLink="tools/seo"
    *ngIf="auth.hasPermissionTo$('access console seo_areas') | async"
    >SEO</a
  >
  <a
    mat-menu-item
    routerLink="tools/basecamp"
    *ngIf="auth.hasPermissionTo$('access console basecamp_admin') | async"
    [matMenuTriggerFor]="menuBasecampTools"
    >Basecamp</a
  >
  <a
    mat-menu-item
    routerLink="tools/open-ai"
    *ngIf="auth.hasPermissionTo$('access console businesses') | async"
    >OpenAI</a
  >
</mat-menu>

<mat-menu #menuScripts="matMenu">
  <a
    mat-menu-item
    routerLink="tools/scripts/master-hd"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Master HD</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts/assets-hd"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Assets HD</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts/promotions"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Promotions</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts/master-all"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Master All</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts/master-gtm"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Master GTM</a
  >
  <a
    mat-menu-item
    routerLink="tools/scripts/search"
    *ngIf="auth.hasPermissionTo$('access console developer_areas') | async"
    >Search</a
  >
</mat-menu>

<mat-menu #menuBasecampTools="matMenu">
  <a
    mat-menu-item
    routerLink="tools/basecamp/user-assign"
    *ngIf="auth.hasPermissionTo$('access console basecamp_admin') | async"
    >Assign User to Basecamps</a
  >
</mat-menu>

<mat-menu #menuTheme="matMenu">
  <button
    mat-menu-item
    (click)="setTheme('light')"
    [ngClass]="{
      'bg-body-bg-light dark:bg-body-bg-dark':
        settingsService.globalTheme === 'light',
    }"
  >
    <fa-icon
      [icon]="['fas', 'sun']"
      [fixedWidth]="true"
      class="pr-2 text-xl"
    ></fa-icon>
    Light Mode
  </button>
  <button
    mat-menu-item
    (click)="setTheme('dark')"
    [ngClass]="{
      'bg-body-bg-light dark:bg-body-bg-dark':
        settingsService.globalTheme === 'dark',
    }"
  >
    <fa-icon
      [icon]="['fas', 'moon']"
      [fixedWidth]="true"
      class="pr-2 text-xl"
    ></fa-icon>
    Dark Mode
  </button>
  <button
    mat-menu-item
    (click)="setTheme('auto')"
    [ngClass]="{
      'bg-body-bg-light dark:bg-body-bg-dark':
        settingsService.globalTheme === 'auto',
    }"
  >
    <fa-icon
      [icon]="['fas', 'eclipse']"
      [fixedWidth]="true"
      class="pr-2 text-xl"
    ></fa-icon>
    System Setting
  </button>
</mat-menu>

<mat-menu #menuAccount="matMenu">
  <a mat-menu-item routerLink="dashboard/settings">Settings</a>
  <button mat-menu-item (click)="showLogs()">
    {{ this.settingsService.globalShowLogs ? "Hide" : "Show" }} Logs
  </button>
  <a
    mat-menu-item
    [href]="nexusNovaURL"
    target="_blank"
    rel="noreferrer"
    *ngIf="auth.hasPermissionTo$('access nova') | async"
  >
    <div class="flex items-center gap-2">
      Nova
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="text-xs"
      ></fa-icon>
    </div>
  </a>
  <a
    mat-menu-item
    [href]="nexusPulseURL"
    target="_blank"
    rel="noreferrer"
    *ngIf="auth.hasPermissionTo$('access pulse') | async"
  >
    <div class="flex items-center gap-2">
      Pulse
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="text-xs"
      ></fa-icon>
    </div>
  </a>
  <a
    mat-menu-item
    [href]="nexusTelescopeURL"
    target="_blank"
    rel="noreferrer"
    *ngIf="auth.hasPermissionTo$('access telescope') | async"
  >
    <div class="flex items-center gap-2">
      Telescope
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="text-xs"
      ></fa-icon>
    </div>
  </a>
  <a mat-menu-item [href]="nexusProfileURL" target="_blank" rel="noreferrer">
    <div class="flex items-center gap-2">
      Profile
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="text-xs"
      ></fa-icon>
    </div>
  </a>
  <button
    mat-menu-item
    (click)="createToken()"
    *ngIf="auth.hasPermissionTo$('access console developer_areas')"
  >
    Create API Token
  </button>
  <button mat-menu-item (click)="auth.logout()">Logout</button>
</mat-menu>

<mat-menu #menuHelp="matMenu">
  <a
    mat-menu-item
    href="https://3.basecamp.com/3194614/buckets/24032556/todolists/4158929204/"
    target="_blank"
    rel="noreferrer"
  >
    Request New Feature
  </a>
  <a
    mat-menu-item
    href="https://3.basecamp.com/3194614/buckets/24032556/todolists/4158918621/"
    target="_blank"
    rel="noreferrer"
  >
    Report Bug
  </a>
  <button mat-menu-item (click)="showReleaseNotes()">Show Release Notes</button>
  <button mat-menu-item (click)="refreshApp()" *ngIf="appUpdateAvailable">
    <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
    Refresh to Update
  </button>

  <div
    class="mt-3 px-2 text-center text-sm text-black opacity-60 dark:text-white"
  >
    {{ appVersion }}
  </div>
</mat-menu>

<!-- API Key -->
<ng-template #apiKeyDialog>
  <code class="mx-0 mb-5 mt-0 block max-h-32 break-all text-sm">{{
    apiKey
  }}</code>
</ng-template>

<!-- Release Notes -->
<ng-template #releaseNotesDialog>
  <div class="release-notes-wrapper">
    <div
      class="release-notes mb-7 rounded-md border border-solid border-black border-opacity-5 bg-black bg-opacity-5 p-4 text-black shadow-md dark:border-black dark:border-opacity-20 dark:bg-opacity-20 dark:text-white"
      *ngFor="let release of releaseNotes"
      [innerHTML]="release.notes | markdown | async"
    ></div>
  </div>
</ng-template>
