import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private updateAvailableSubject = new BehaviorSubject<
    VersionEvent['type'] | null
  >(null);

  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.versionUpdates.subscribe((event) => {
      this.updateAvailableSubject.next(event.type);
    });

    interval(60 * 1000).subscribe(() => {
      this.swUpdate.checkForUpdate().catch((reason) => {
        // ignore update errors
      });
    });
  }

  $checkForUpdates(): BehaviorSubject<VersionEvent['type'] | null> {
    return this.updateAvailableSubject;
  }
}
