import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { CmmApiService } from './helpers/cmm-api.service';
import { ConsoleUser, UserRole } from '@helpers/types/user';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { getBooleanChanges, RemoteConfig } from '@angular/fire/remote-config';
import { UserSettingsService } from './user-settings.service';
import {
  APIType,
  APITypeCreating,
  APITypeUpdating,
} from '@helpers/types/api-types.type';

export type NotificationData = APIType<
  {
    content: string;
    description?: string;
    is_visible?: boolean;
    basecamp_category?: number;
    basecamp_message_id?: number;
    /**
     * MySQL DateTime format YYYY-MM-DD HH-MM-SS
     */
    time_show?: Date;
    /**
     * MySQL DateTime format YYYY-MM-DD HH-MM-SS
     */
    time_hide?: Date;
    user_read?: boolean;
    all_users_read?: ConsoleUser[];
    created_at?: string;
  },
  'all_users_read'
>;

type WebsocketNotificationData = {
  notification: NotificationData;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends CmmApiService {
  protected override serviceName = 'NotificationService';

  notifications: NotificationData[] = [];

  private notificationURL = CmmApiService.API_URL + '/notifications';

  private roles: UserRole[] = [];

  private notificationSound: HTMLAudioElement;
  private playNotificationAudio = true;

  private userService = inject(UserService);
  private websocket = inject(WebsocketService);
  private auth = inject(AuthService);
  private remoteConfig = inject(RemoteConfig);
  private settingsService = inject(UserSettingsService);

  constructor() {
    super();

    // Load notification audio
    this.notificationSound = new Audio();
    this.notificationSound.src = '/assets/audio/hollow-hit-03.mp3';
    this.notificationSound.load();

    getBooleanChanges(this.remoteConfig, 'playNotificationAudio').subscribe(
      (value: boolean) => {
        this.playNotificationAudio = value;
      },
    );

    // Start listening for notifications after getting user/role data
    this.userService
      .getUserByID()
      .pipe(switchMap((_) => this.userService.getAllRoles()))
      .subscribe((roles) => {
        this.roles = roles;

        this.listenForNotifications();
      });
  }

  private playNotificationSound(): void {
    if (
      this.settingsService.globalNotificationSound &&
      this.playNotificationAudio
    ) {
      if (this.notificationSound.paused) {
        this.notificationSound
          .play()
          .catch((e) => this.loggingService.log(this.serviceName, e));
      } else {
        this.notificationSound.currentTime = 0;
      }
    }
  }

  private assignNotification(newNotification: NotificationData): void {
    let notification = this.notifications.find(
      (n) => n.id === newNotification.id,
    );
    if (notification) {
      Object.assign(notification, newNotification);
    } else {
      this.notifications.push(newNotification);

      if (this.isVisibleNotification(newNotification)) {
        this.playNotificationSound();
      }
    }
  }

  private removeNotification(notification: NotificationData) {
    this.notifications = this.notifications.filter(
      (n) => n.id !== notification.id,
    );
  }

  private listenForNotifications(): void {
    this.auth.isAuthenticated$
      .pipe(skipWhile((loggedIn) => !loggedIn))
      .subscribe((_) => {
        this.getNotifications().subscribe((response) => {
          response.forEach((newNotification) => {
            this.assignNotification(newNotification);
          });
        });

        this.userService.getUserByID().subscribe((_) => {
          const channelName = this.userService.hasPermission(
            'ManageNotifications',
          )
            ? 'notifications_admin'
            : 'notifications';

          this.websocket
            .listen<WebsocketNotificationData>(channelName, [
              'notification.created',
              'notification.updated',
              'notification.deleted',
            ])
            .subscribe((response) => {
              if (!response.data) return;

              let notification = response.data.notification;

              switch (response.type) {
                case 'notification.created':
                case 'notification.updated':
                  this.assignNotification(notification);
                  break;
                case 'notification.deleted':
                  this.removeNotification(notification);
                  break;
              }
            });
        });
      });
  }

  getNotifications(): Observable<NotificationData[]> {
    return this.http
      .get<NotificationData[]>(this.notificationURL, this.httpOptions)
      .pipe(
        tap((response) => {
          if (response.length === 0) {
            this.log('fetched 0 notifications');
          } else {
            response.forEach((notification) =>
              this.log('fetched notification: ' + notification.content),
            );
          }
        }),
        catchError(this.handleError<NotificationData[]>('getNotifications')),
        map((notifications) => notifications ?? []),
        this.trace('getNotifications'),
      );
  }

  addNotification(
    notification: APITypeCreating<NotificationData>,
  ): Observable<NotificationData | null> {
    let data = notification;

    return this.http
      .post<NotificationData>(this.notificationURL, data, this.httpOptions)
      .pipe(
        tap((_) =>
          this.log(`added notification message=${notification.content}`),
        ),
        catchError(this.handleError<NotificationData>('addNotification')),
        this.trace('addNotification'),
      );
  }

  updateNotification(
    id: number,
    notification: APITypeUpdating<NotificationData>,
  ): Observable<NotificationData | null> {
    const url = `${this.notificationURL}/${id}`;

    return this.http
      .patch<NotificationData>(url, notification, this.httpOptions)
      .pipe(
        tap((_) => this.log(`updated notification id=${id}`)),
        catchError(this.handleError<NotificationData>('updateNotification')),
        this.trace('updateNotification'),
      );
  }

  deleteNotification(
    notification_id: number,
  ): Observable<NotificationData | null> {
    const url = `${this.notificationURL}/${notification_id}`;
    return this.http.delete<NotificationData>(url, this.httpOptions).pipe(
      tap((_) => this.log(`deleted notification id=${notification_id}`)),
      catchError(this.handleError<NotificationData>('deleteNotification')),
      this.trace('deleteNotification'),
    );
  }

  markNotificationAsRead(
    notification_id: number,
  ): Observable<NotificationData | null> {
    const url = `${this.notificationURL}/${notification_id}/read`;

    const updatingNotification = this.notifications.find(
      (n) => n.id === notification_id,
    );

    if (updatingNotification) {
      updatingNotification.user_read = true;
    }

    return this.http.patch<NotificationData>(url, {}, this.httpOptions).pipe(
      tap((_) => this.log(`deleted notification id=${notification_id}`)),
      catchError(this.handleError<NotificationData>('deleteNotification')),
      this.trace('deleteNotification'),
    );
  }

  public countVisibleNotifications(): number {
    return this.notifications.reduce((prev, curr) => {
      if (this.isVisibleNotification(curr)) {
        return ++prev;
      }

      return prev;
    }, 0);
  }

  public isVisibleNotification(notification: NotificationData): boolean {
    // check if the notification should be shown to the user
    let defaultVisible = !!(
      notification.is_visible &&
      !notification.user_read &&
      notification.basecamp_category !== 63352494
    );

    // check if the notification's role (based on the Basecamp category ID) if a part of the user
    let roleVisible = true;
    if (notification.basecamp_category) {
      let notificationRole = this.roles?.find(
        (role) => role.basecamp_category_id === notification.basecamp_category,
      );

      if (notificationRole) {
        roleVisible = this.userService.userHasRole(notificationRole);
      }
    }

    return defaultVisible && roleVisible;
  }
}
