import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CreekmoreTeam } from '@helpers/types/client';
import { UserCardComponent } from '@shared/components/user-card/user-card.component';
import { ClientService } from '@services/client.service';
import { SidenavService } from '@services/sidenav.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-teams',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    UserCardComponent,
    MatToolbarModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
  ],
  templateUrl: './teams.component.html',
  styleUrl: './teams.component.scss',
})
export class TeamsComponent implements OnInit {
  teams: CreekmoreTeam[] = [];

  constructor(private clientService: ClientService) {}

  ngOnInit(): void {
    this.clientService.getTeams().subscribe((teams) => (this.teams = teams));
  }
}
