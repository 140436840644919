import {
  enableProdMode,
  Provider,
  EnvironmentProviders,
  importProvidersFrom,
} from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideServiceWorker } from '@angular/service-worker';
import { RemoteConfigSettings } from '@services/firebase-remote-config.service';
import {
  provideRemoteConfig,
  getRemoteConfig,
} from '@angular/fire/remote-config';
import { setAnalyticsCollectionEnabled } from '@firebase/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import {
  MARKED_OPTIONS,
  MarkedOptions,
  MarkedRenderer,
  provideMarkdown,
} from 'ngx-markdown';
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
} from 'ngx-webstorage';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  MonacoEditorModule,
  NgxMonacoEditorConfig,
} from 'ngx-monaco-editor-v2';
import * as MonacoType from 'monaco-editor';
import * as MonokaiTheme from '@assets/themes/Monokai.json';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { autoLoginInterceptor } from './app/interceptors/auto-login.interceptor';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import {
  UserTrackingService,
  provideAnalytics,
  getAnalytics,
} from '@angular/fire/analytics';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideRouter } from '@angular/router';
import { ROUTES } from './app/routes';

import * as Sentry from '@sentry/angular';

Sentry.init({
  enabled: environment.envName !== 'local',
  environment: environment.envName,
  release: `creekmore-marketing-dashboard@${environment.version}`,
  dsn: 'https://4526de519ac326ea0f0d4033df1068c3@o4507028474691584.ingest.us.sentry.io/4507256401297408',
  integrations: [
    Sentry.browserTracingIntegration(),
    //Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/(dev\.)?creekmore\.marketing\/api/, // IDEA: Maybe use the environment.apiUrl in the future
    /^https:\/\/cmmapi.local\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function generateProvidersArray(
  localProviders: Provider[],
  otherProviders: (Provider | EnvironmentProviders)[],
) {
  if (environment.envName === 'local') {
    return localProviders.concat(otherProviders);
  } else {
    return otherProviders;
  }
}

const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: () => {
    monaco.editor.defineTheme('monokai', (MonokaiTheme as any).default);

    if (document.documentElement.classList.contains('dark')) {
      monaco.editor.setTheme('monokai');
    } else {
      monaco.editor.setTheme('vs');
    }

    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
    });

    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true, //FIXME: Set to false, but do code cleanup
      noSyntaxValidation: false,
    });
  },
};

declare var monaco: typeof MonacoType;

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
  };

  const options: MarkedOptions = {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };

  return options;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    /**
     * ============================
     *
     * Old Module Providers
     *
     * ============================
     */
    importProvidersFrom([
      BrowserModule,
      CommonModule,
      FormsModule,
      MatMenuModule,
      MatToolbarModule,
      MatSidenavModule,
      MatButtonModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      MatTableModule,
      MatSortModule,
      MatExpansionModule,
      MatSnackBarModule,
      MatBadgeModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      ClipboardModule,
      FontAwesomeModule,
      NgxPicaModule,
      MonacoEditorModule.forRoot(monacoConfig),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
    ]),

    /**
     * ============================
     *
     * Regular Providers
     *
     * ============================
     */
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.envName === 'local') {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    provideAnalytics(() => {
      const analytics = getAnalytics();
      if (environment.envName === 'local') {
        setAnalyticsCollectionEnabled(analytics, false);
      }
      return analytics;
    }),
    provideRemoteConfig(() => {
      const remoteConfig = getRemoteConfig();
      if (environment.envName === 'local') {
        remoteConfig.settings.minimumFetchIntervalMillis = 10000;
        remoteConfig.defaultConfig = {
          playNotificationAudio: true,
        } as RemoteConfigSettings;
      }
      return remoteConfig;
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ...generateProvidersArray(
      [],
      [
        MatDatepickerModule,
        UserTrackingService,
        provideHttpClient(withInterceptors([autoLoginInterceptor])),
      ],
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideRouter(ROUTES),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'cmm-console' }),
      withLocalStorage(),
    ),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
  ],
}).catch((err) => console.error(err));
