<mat-toolbar-row
  id="cmm-notifications"
  class="bg-warn"
  *ngIf="notificationService.countVisibleNotifications()"
>
  <ul
    #notificationWrapper
    (mouseenter)="stopSwitchLoop()"
    (mouseleave)="startSwitchLoop()"
  >
    <ng-container *ngFor="let noti of notificationService.notifications">
      <li *ngIf="notificationService.isVisibleNotification(noti)">
        {{ noti.content }}
        <button
          mat-stroked-button
          (click)="openNotificationDetails(noti)"
          *ngIf="noti.description"
        >
          See Details
        </button>
      </li>
    </ng-container>
  </ul>
</mat-toolbar-row>

<ng-template #notificationDetails>
  <h3>
    {{ notificationTitle }}
  </h3>
  <p>
    {{ notificationDescription }}
  </p>
</ng-template>
