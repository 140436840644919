import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BasecampService } from '@services/basecamp.service';
import { FormTemplateService } from '@services/forms/form-template.service';

import {
  NotificationData,
  NotificationService,
} from '@services/notification.service';
import { tap } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MatToolbarRow } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [CommonModule, MatToolbarRow, MatButton],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationWrapper') notificationWrapper: ElementRef | undefined;

  @ViewChild('notificationDetails') notificationDetails:
    | TemplateRef<any>
    | undefined;
  public notificationTitle: string = '';
  public notificationDescription: string = '';

  private changeInterval: number = 0;
  private currentNotificationIndex = 0;

  private NOTIFICATION_INTERVAL = 5000;

  constructor(
    public notificationService: NotificationService,
    private basecampService: BasecampService,
    private formTemplateService: FormTemplateService,
  ) {}

  ngOnInit() {
    this.startSwitchLoop();
  }

  ngOnDestroy() {
    this.stopSwitchLoop();
  }

  public startSwitchLoop() {
    this.changeInterval = window.setInterval(() => {
      this.switchNotification();
    }, this.NOTIFICATION_INTERVAL);
  }

  public stopSwitchLoop() {
    clearInterval(this.changeInterval);
  }

  private switchNotification(numToSwitch = 1): void {
    if (this.notificationWrapper) {
      let tempIndex =
        (this.currentNotificationIndex + numToSwitch) %
        this.notificationService.countVisibleNotifications();
      if (isNaN(tempIndex)) {
        tempIndex = 0;
      }

      this.currentNotificationIndex = tempIndex;
      let transform = `translateX(-${this.currentNotificationIndex * 100}%)`;
      this.notificationWrapper.nativeElement.style.transform = transform;
    }
  }

  public openNotificationDetails(noti: NotificationData) {
    if (!noti.description) return;

    this.notificationTitle = noti.content;
    this.notificationDescription = noti.description;

    const obsForm = this.formTemplateService.open(this.notificationDetails, {
      width: '600px',
      data: {
        hideCancelButton: true,
        headerText: 'Notification Details',
        buttonText: 'Dismiss',
        buttonAlt: {
          value: true,
          text: 'Mark as Read',
        },
      },
    });

    obsForm.subscribe((form) => {
      form.afterClosed((result) => {
        if (result === true) {
          // TODO: Comment on the Basecamp message with "Read"
          return this.notificationService.markNotificationAsRead(noti.id).pipe(
            tap((_) => {
              this.switchNotification(0);
            }),
          );
        }
        return;
      });
    });
  }
}
