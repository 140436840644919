<div
  class="mt-3 flex flex-col"
  [ngClass]="sidenavService.iconsOnly ? 'icons-only' : ''"
>
  <ng-container *ngFor="let item of sidenavService.sideMenu">
    <a
      mat-button
      *ngIf="sidenavService.auth.hasPermissionTo$(item.permission) | async"
      routerLink="{{ item.link }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: item.useExact ?? false }"
      [matTooltip]="item.name"
      [matTooltipDisabled]="!sidenavService.iconsOnly"
      matTooltipPosition="after"
      class="block min-w-[230px] flex-grow basis-full py-3 text-left align-middle text-base text-neutral-700 dark:text-white [&.active]:text-primary"
    >
      <fa-icon
        [icon]="['fas', item['icon']]"
        class="inline-block w-14 px-3 text-center text-2xl"
      ></fa-icon>
      <span class="button-text">{{ item.name }}</span>
    </a>
  </ng-container>
</div>

<button
  mat-icon-button
  class="absolute bottom-3 right-3"
  (click)="sidenavService.setIconVisibility(!sidenavService.iconsOnly)"
>
  <fa-icon
    [icon]="['fas', 'chevron-left']"
    *ngIf="!sidenavService.iconsOnly"
  ></fa-icon>
  <fa-icon
    [icon]="['fas', 'chevron-right']"
    *ngIf="sidenavService.iconsOnly"
  ></fa-icon>
</button>
