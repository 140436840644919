<div
  class="fab-wrapper fixed bottom-4 right-6 z-50"
  *ngIf="fabService.isVisible"
>
  <button
    mat-fab
    class="fab-main z-20 drop-shadow-lg"
    (click)="fabService.clickAction()"
    [matTooltip]="fabService.tooltipText"
    [matTooltipDisabled]="!fabService.tooltipText"
    matTooltipPosition="left"
  >
    <fa-icon [icon]="['fas', 'pen']"></fa-icon>
  </button>
  <div
    class="fab-menu absolute bottom-12 right-2 z-10 flex flex-col-reverse items-end gap-3"
  >
    <ng-container *ngFor="let item of fabService.fabMenu">
      <div
        class="fab-sub-wrapper flex flex-row-reverse gap-3"
        [matTooltip]="item.name"
        matTooltipPosition="left"
      >
        <ng-container
          *ngTemplateOutlet="
            menuItemWrapper;
            context: { menuItem: item, tooltipPosition: 'left' }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template
  #menuItemWrapper
  let-menuItem="menuItem"
  let-tooltipPosition="tooltipPosition"
>
  <a
    mat-mini-fab
    [color]="menuItem.color ?? 'accent'"
    href="{{ menuItem.url }}"
    target="{{ menuItem.target }}"
    [matTooltip]="menuItem.name"
    [matTooltipDisabled]="tooltipPosition === 'left'"
    [matTooltipPosition]="tooltipPosition"
    *ngIf="menuItem.url"
    class="fab-sub-main z-20 drop-shadow-lg [&.mat-basic]:backdrop-blur-md [&.mat-basic]:backdrop-invert-[30%]"
  >
    <ng-container
      *ngTemplateOutlet="menuItemContent; context: { menuItem: menuItem }"
    ></ng-container>
  </a>
  <button
    mat-mini-fab
    [color]="menuItem.color ?? 'accent'"
    [matTooltip]="menuItem.name"
    [matTooltipDisabled]="tooltipPosition === 'left'"
    [matTooltipPosition]="tooltipPosition"
    *ngIf="menuItem.click"
    (click)="menuItem.click()"
    class="fab-sub-main z-20 drop-shadow-lg [&.mat-basic]:backdrop-blur-md [&.mat-basic]:backdrop-invert-[30%]"
  >
    <ng-container
      *ngTemplateOutlet="menuItemContent; context: { menuItem: menuItem }"
    ></ng-container>
  </button>

  <ng-container *ngFor="let item of menuItem.menu">
    <ng-container
      *ngTemplateOutlet="
        menuItemWrapper;
        context: { menuItem: item, tooltipPosition: 'above' }
      "
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #menuItemContent let-menuItem="menuItem">
  <fa-icon
    *ngIf="menuItem.icon; else noIcon"
    [icon]="['fas', menuItem['icon']]"
  ></fa-icon>
  <ng-template #noIcon>
    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
  </ng-template>
  <div
    *ngIf="menuItem.subIcon"
    class="absolute right-0 top-0 flex h-5 w-5 items-center justify-center rounded-full bg-white text-sm leading-[0] text-red-600 shadow-sm"
  >
    <fa-icon
      [icon]="['fas', menuItem['subIcon']]"
      [fixedWidth]="true"
    ></fa-icon>
  </div>
</ng-template>
