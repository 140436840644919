import { UrlMatcher, UrlSegment } from '@angular/router';

import { authGuard } from '@guards/auth.guard';
import { pwaRedirectGuard } from '@guards/pwa-redirect.guard';
import { AppRoutes } from './app-route';
import { TeamsComponent } from '@pages/teams/teams.component';

const matchPWAURL: UrlMatcher = (url) => {
  if (url.length === 0) return null;

  const protocol = 'web+cmmconsole://';
  const path = url[0].path;

  if (path.startsWith(protocol)) {
    console.log('protocol route found: ' + path);
    return {
      consumed: url,
      posParams: {
        newPath: new UrlSegment(path.slice(protocol.length), {}),
      },
    };
  }

  return null;
};

export const ROUTES: AppRoutes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  // Fixes an issue where the browser won't go to the dashboard when initially logging in
  {
    path: 'http:',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'https:',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  // PWA "web+cmmconsole" protocol handler
  {
    matcher: matchPWAURL,
    canActivate: [pwaRedirectGuard],
    children: [],
  },

  /*=============================================================
  DASHBOARD
  =============================================================*/
  {
    path: 'dashboard',
    loadChildren: () => import('@pages/dashboard/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console businesses',
    },
  },

  /* ============================================================
  CLIENTS
  =============================================================*/
  {
    path: 'clients',
    loadChildren: () => import('@pages/clients/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console businesses',
    },
  },

  /* ============================================================
  TEAMS
  =============================================================*/
  {
    path: 'teams',
    component: TeamsComponent,
    canActivate: [authGuard],
    data: {
      permission: 'access console businesses',
      title: 'Teams',
    },
  },

  /* ============================================================
  TOOLS
  =============================================================*/
  {
    path: 'tools/hd-errors',
    loadChildren: () => import('@pages/tools/hdstatic-errors/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console admin_areas',
    },
  },
  {
    path: 'tools/calculators',
    loadChildren: () => import('@pages/tools/calculators/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console businesses',
    },
  },
  {
    path: 'tools/scripts',
    loadChildren: () => import('@pages/tools/scripts/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console developer_areas',
    },
  },
  {
    path: 'tools/image-optimizer',
    loadChildren: () => import('@pages/tools/image-optimizer/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console developer_areas',
    },
  },
  {
    path: 'tools/seo',
    loadChildren: () => import('@pages/tools/seo/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console seo_areas',
    },
  },
  {
    path: 'tools/basecamp',
    loadChildren: () => import('@pages/tools/basecamp/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console basecamp_admin',
    },
  },
  {
    path: 'tools/open-ai',
    loadChildren: () => import('@pages/tools/open-ai/routes'),
    canActivate: [authGuard],
    data: {
      permission: 'access console businesses',
    },
  },
];
