import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsoleUser } from '@helpers/types/user';
import { FormTemplateService } from '@services/forms/form-template.service';
import { UserHelperService } from '@services/user-helper.service';
import { MatTooltip } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltip],
})
export class UserDialogComponent implements OnInit {
  private dialogRef: MatDialogRef<any, any> | null = null;

  @ViewChild('userDetailsDialog')
  userDetailsDialog: TemplateRef<any> | undefined;

  user!: ConsoleUser;

  constructor(
    private dialog: MatDialog,
    private userHelperService: UserHelperService,
    private formTemplateService: FormTemplateService,
  ) {}

  ngOnInit(): void {
    this.userHelperService.listenForOpenDialog().subscribe((user) => {
      this.openUserDialog(user);
    });
  }

  openUserDialog(user: ConsoleUser): void {
    if (this.dialogRef == null) {
      this.user = user;

      this.formTemplateService.open(this.userDetailsDialog);
    }
  }
}
