import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@root/environments/environment';
import { AuthService } from '@services/auth.service';
import { catchError, switchMap, throwError } from 'rxjs';

export const autoLoginInterceptor: HttpInterceptorFn = (req, next) => {
  // only check CMM API requests
  if (!req.url.includes(environment.apiUrl)) {
    return next(req);
  }

  const auth = inject(AuthService);

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      // show login prompt if response status is 401 (unauthenticated)
      if (err.status === 401) {
        return auth.login(true, false).pipe(
          switchMap((loggedIn) => {
            if (!loggedIn) {
              return throwError(() => err);
            }

            return next(req);
          }),
        );
      }

      return throwError(() => err);
    }),
  );
};
